import { richText, stripRichTextWrapperTag } from '../../utils/html';
import { adapter as imageAdapter } from '@/components/cloud-image/utils';

export default ({ primary, items }, { enhancedLinkSerializer }) => {
  const withoutContent =
    !primary.title && !primary.subtitle && !primary.description;

  return {
    titleTag: primary.titleTag ? 'h1' : 'h2',
    title: primary.title && stripRichTextWrapperTag(primary.title),
    subtitle: primary.subtitle,
    description: primary.description && richText(primary.description),
    cards: items.map(({ label, icon, link }) => ({
      label,
      icon: imageAdapter(icon),
      link: enhancedLinkSerializer(link),
    })),
    isLogoVariant: primary.is_logo_variant,
    isSingleColumn: primary.is_single_column || withoutContent,
    hasSmallMargin: primary.has_small_margin,
    isSingleColumnCentered: primary.is_single_column_centered,
  };
};
