
import { getSliceComponentProps } from '@prismicio/vue/components';
import mosaicPushAdapter from '@/slices/MosaicPush/adapter';

export default {
  name: 'MosaicPush',
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
  computed: {
    sliceData() {
      return mosaicPushAdapter(this.slice, {
        enhancedLinkSerializer: this.$enhancedLinkSerializer,
      });
    },
  },
};
